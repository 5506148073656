/* src/css/footer.css */

.meetu-footer {
    background: #969696;
    color: black;
    padding: 5px 10px;
}

.footer-modal-button {
    width: 100%;
}

.footer-contact-info{
    text-align:end;
}

.footer-contact-modal{
    justify-content: center;
    align-content: center;
}
