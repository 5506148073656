/* Header.css */

.navbar-custom {
    background-color: #fff;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
}

.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navbar-brand {
    color: #000;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    align-items: center;
}

.nav-link {
    color: #000 !important;
    margin: 0 15px;
    font-size: 16px;
    font-weight: bold;
}

    .nav-link:hover {
        color: #555 !important;
    }

button {
    margin: 10px;
}

    button:focus {
        outline: none;
        box-shadow: none;
    }

.nav-logo {
    height: 3rem;
    width: auto;
    margin: 10px;
}
