.about-us-banner-section {
    position: relative;
    height: auto;
}

.our-aim-section {
    position: relative;
    height: auto;
}

.about-banner-image {
    padding-top:3%;
}

.about-banner-text-1 {
    padding-top: 50%;
    padding-left: 10%
}

.about-banner-text-2 {
    padding-top: 50%;
    padding-right: 10%
}

.about-banner-h1 {
    position:absolute;
    top:18%;
    left:35%;
    color: black;
    font-size: 100px
}

.who-are-we-h1{
    text-align:center;
    margin-bottom: 2%;
    margin-top:2%
}

