/* Home.css */

/* overall style sheet */
body {
    font-family: "Raleway", sans-serif;
    background-color: #ffffff; /* bg color */
    margin: 0;
    padding: 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.home-page {
    padding: 40px 0;
}

.hero-image {
    max-width: 100%;
    border-radius: 10px;
}

.search-form {
    display: flex;
    margin-top: 20px;
}

.search-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    font-size: 16px;
}

.search-button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.about-section {
    background-color: #fff;
    padding: 60px 20px;
    text-align: left;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

    .about-section h2 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .about-section p {
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 20px;
    }

    .about-section .btn-primary {
        background-color: #007bff;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 5px;
    }

        .about-section .btn-primary:hover {
            background-color: #0056b3;
        }

.features-section {
    background-color: #fff;
    padding: 40px 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-box {
    padding: 20px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
}

.new-on-meet-section {
    background-color: #fff;
    padding: 40px 20px;
    text-align: left;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

    .new-on-meet-section h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
    }

.tabs {
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
}

.tab-button {
    padding: 10px 20px;
    margin: 0 5px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: #007bff;
}

    .tab-button.active {
        border-bottom: 2px solid #007bff;
        color: #000;
    }

.people-row {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}

.person {
    text-align: center;
    margin: 10px;
}

    .person p {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 600;
        color: #6c757d;
    }
