.banner-section {
    position: relative;
    height: auto;
}

.sponsor-banner-h1 {
    position:absolute;
    top:35%;
    left:35%;
    color: white
}


.index-content {
    margin-top: 15%;
    margin-left: 20%;
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    h2 {
        margin-bottom: 10%;
    };
}

.index-item {
    margin-bottom: 2%;
    width:50%;
    a {
        text-decoration: none;
        color: black;
    }
    hr {
        width: 100%;
    }
    a:hover {
        color:dodgerblue
    }
}

.content-section {
    padding-right: 5%
}
